.experience-card_background{
    background-color: $experienceColour,
}

.experience-card_container{
    display:flex;
    margin: 2rem 0rem 2rem 0rem;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 5px #5183a4; //7ecafd
    transition: all 0.3s ease-in-out;

    &.experience-card_container_clickable{
        border: 0.2rem solid #6fbffd;
        cursor:pointer;
        
        &:hover{
            box-shadow: 8px 8px 10px #516a7c;
        }
    }
}

.experience-card_simple-text-container{
    transition: all 0.3s ease-in-out;
}

.experience-card_simple-text-container{
    margin-left: 2rem;
}