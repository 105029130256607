.title-card_home{
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%);
    &video {
        object-fit: cover;
    }
    z-index: -100;
}


.title-card_overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}