$xs: 200px;
$sm: 300px;
$md: 600px;
$lg: 900px;
$xl: 1200px;
$xxl: 1536px;
$xxxl: 1920px;

//Colours
$contactMeColour: #D4D4D4;
$buildDetails: #6e6e6e;
$techStackColour: #97caea;
$experienceColour: #aadeff;

@import "../styles/GeneralStyle";
@import "../styles/TitleCardStyles";
@import "../styles/AboutCardStyle";
@import "../styles/TechStackStyle";
@import "../styles/NavigationBarStyle";
@import "../styles/FooterStyle";
@import "../styles/AllCardsStyle";
@import "../styles/ExperienceCardStyle";
@import "../styles/ProjectsCarousel";
@import "../styles/ProjectsStyle";